require('./external');

require('./proscore');

$(function() {



  $("body").tooltip({ selector: '[data-bs-toggle="tooltip"]', trigger: 'click', placement: 'bottom' });


  function sidebarBackground(action = 'open'){
    if(action == 'open'){
      $('header, main').addClass('blur');
      $('body').addClass('overflow-hidden');
      $('.overlay').addClass('active');
      return;
    }

    $('header, main').removeClass('blur');
    $('body').removeClass('overflow-hidden');
    $('.overlay').removeClass('active');
  }

  $('[data-masked]').each((i, e) => {
    let input = $(e);
    let type = input.attr('data-masked');

    if (type == 'date') {
      input.mask('00/00/0000');
      return;
    }

    if (type == 'phone') {
      input.mask('(00) 00000-0000');
      return;
    }

    if(type == 'money') {
      input.mask('000.000.000.000.000,00', {reverse: true});
      return;
    }
  });

  $('.accordion .header').on('click', (e) => {
    let accordion = $(e.currentTarget).closest('.accordion');
    accordion.toggleClass('opened');
  });

  $('.open-sidebar').on('click', (e) => {
    e.preventDefault();
    let sidebar = $(e.currentTarget).attr('data-sidebar');
    sidebarBackground('open');
    $(`section.sidebar.opened`).each((index, element) => {
      $(element).removeClass('opened');
    });
    $(`section#${sidebar}`).toggleClass('opened');
  });

  $('.close-sidebar').on('click', (e) => {
    const sidebar = $(e.currentTarget).closest('.sidebar');
    if (sidebar.is('.opened')) {
      sidebarBackground('close');
      sidebar.toggleClass('opened');
    }
  });

  $('[data-toggle-on-change]').on('change', (e) => {
    const value = $(e.currentTarget).val();
    const target = $(e.currentTarget).data('toggle-on-change');
    $(`${target} > :not([data-toggle="${value}"])`).slideUp(100, () => {
      $(`${target} > [data-toggle="${value}"]`).slideDown();
    });
  });

  // data-toggle-on-change=".key-type-values-content"
});


