const Swal = require('sweetalert2');

$(function() {

  if (!$('body').is('.proscore')) {
    return;
  }

  var dominio_api = "https://proscore.com.br/naveg/";

  document.getElementById("abrirCameraPfParteUm").style.display = "none"
  document.getElementById("abrirCameraPfSelfie").style.display = "none"
  document.getElementById("abrirCameraPjSelfie").style.display = "none"
  document.getElementById("abrirCameraPjParteUm").style.display = "none"
  document.querySelector(".msgUser1").style.display = "none"
  document.querySelector(".msgUser2").style.display = "none"

  // Password
  let pswrd = document.getElementById("senhaPF");
  let pswrdPj = document.getElementById("senhaPJ");

  document.querySelector(".verificacaoSenha").style.display = 'none';
  document.querySelector(".verificacaoSenhaPJ").style.display = 'none';
  document.getElementById("senhaPFVerificacao").style.display = 'none';
  document.getElementById("senhaPJVerificacao").style.display = 'none';

  // validation password - let
  let lowerCase = document.getElementById("lower");
  let upperCase = document.getElementById("upper");
  let digit = document.getElementById("number");
  let specialChar = document.getElementById("special");
  let minLength = document.getElementById("length");

  function toggleLoading(sidebar, show = true) {
    const sidebar_el = $(`${sidebar}.sidebar`)
    if(sidebar_el.length == 0) {
      return;
    }

    if(show) {
      sidebar_el.addClass('is-loading');
      return;
    }

    sidebar_el.removeClass('is-loading');
  }


  $('[data-request-form-step]').on('click', (e) => {
    const step = $(e.currentTarget).data('request-form-step');
    switch (step) {
      case 'form-step-1':
        requestFormularioStep1();
        inputDigits('pf_PhoneCode');
        break;
      case 'request-resend-code':
        requestResendCode();
        break;
      case 'request-resend-code-pj':
        requestResendCodePj();
        break;
      case 'form-step-2':
        codigoSMS();
        inputDigits('pf_EmailCode');
        break;
      case 'form-step-3':
        requestFormularioValidacao();
          break;
      case 'form-step-4':
        DadosPfEtapa4();
          break;
      case 'pj-form-step-2':
        codigoSMSPj();
        inputDigits('pj_EmailCode');
          break;
      case 'pj-form-step-3':
        requestFormularioValidacaoPj();
          break;
      default:
        break;
    }
  });
  $('[data-on-click]').on('click', (e) => {
    const step = $(e.currentTarget).data('on-click');
    switch (step) {
      case 'input-file-pf':
        inputFilePf();
        break;
      case 'input-file-pj':
        inputFilePj();
        break;
      case 'load-camera':
        loadCamera();
        break;
      case 'load-camera-pj':
        loadCameraPj();
        break;
      case 'input-file-pf-2':
        inputFilePf2();
        break;
      case 'input-file-pj-2':
        inputFilePj2();
        break;
      case 'request-form-selfie':
        requestFormularioSelfie();
        break;
      case 'request-form-selfie-pj':
        requestFormularioSelfiePj();
        break;
      case 'request-form-address':
        requestFormularioEndereco();
        break;
      case 'request-form-address-pj':
        requestFormularioEnderecoPJ();
        break;
      case 'pj-form-step-1':
        requestFormularioEtapa1PJ();
        inputDigits('pj_PhoneCode');
        break;

      default:
        break;
    }
  });

  function handleInput(e) {
    var ss = e.target.selectionStart;
    var se = e.target.selectionEnd;
    e.target.value = e.target.value.toUpperCase();
    e.target.selectionStart = ss;
    e.target.selectionEnd = se;
  }

  $('[data-handle-input]').on('input', (e) => {
    const action = $(e.currentTarget).data('handle-input');
    switch (action) {
      default:
        handleInput(e)
        break;
    }
  });

  $('[data-onkeyup]').on('keyup', (e) => {
    const action = $(e.currentTarget).data('onkeyup');
    const value = $(e.currentTarget).val();
    switch (action) {
      case 'check-password':
        checkPassword(value)
        break;
      case 'check-password-pj':
        checkPasswordPJ(value)
        break;
      default:
        break;
    }
  });

  function checkPassword(data) {
    // javascript regular expression patern
    const lower = new RegExp("(?=.*[a-z])");
    const upper = new RegExp("(?=.*[A-Z])");
    const number = new RegExp("(?=.*[0-9])");
    const special = new RegExp("(?=.*[!@#$%^&*()+=:;<~>_/?`])");
    const length = new RegExp("(?=.{8,})");

    // lowercase validation check
    if (lower.test(data)) {
      lowerCase.classList.add("valid");
    } else {
      lowerCase.classList.remove("valid");
    }

    // upperrcase validation check
    if (upper.test(data)) {
      upperCase.classList.add("valid");
    } else {
      upperCase.classList.remove("valid");
    }

    // number validation check
    if (number.test(data)) {
      digit.classList.add("valid");
    } else {
      digit.classList.remove("valid");
    }

    // special character validation check
    if (special.test(data)) {
      specialChar.classList.add("valid");
    } else {
      specialChar.classList.remove("valid");
    }

    // minLength validation check
    if (length.test(data)) {
      minLength.classList.add("valid");
    } else {
      minLength.classList.remove("valid");
    }

    if(length.test(data) && special.test(data) && number.test(data) && upper.test(data) && lower.test(data)){

      document.querySelector(".verificacaoSenha").style.display = 'block';
      document.getElementById("senhaPFVerificacao").style.display = 'block';
    }
  }

  let lowerCasePJ = document.getElementById("lowerPJ");
  let upperCasePJ = document.getElementById("upperPJ");
  let digitPJ = document.getElementById("numberPJ");
  let specialCharPJ = document.getElementById("specialPJ");
  let minLengthPJ = document.getElementById("lengthPJ");

  function checkPasswordPJ(data) {
    // javascript regular expression patern
    const lower = new RegExp("(?=.*[a-z])");
    const upper = new RegExp("(?=.*[A-Z])");
    const number = new RegExp("(?=.*[0-9])");
    const special = new RegExp("(?=.*[!@#$%^&*()+=:;<~>_/?`])");
    const length = new RegExp("(?=.{8,})");

    // lowercase validation check
    if (lower.test(data)) {
      lowerCasePJ.classList.add("valid");
    } else {
      lowerCasePJ.classList.remove("valid");
    }

    // upperrcase validation check
    if (upper.test(data)) {
      upperCasePJ.classList.add("valid");
    } else {
      upperCasePJ.classList.remove("valid");
    }

    // number validation check
    if (number.test(data)) {
      digitPJ.classList.add("valid");
    } else {
      digitPJ.classList.remove("valid");
    }

    // special character validation check
    if (special.test(data)) {
      specialCharPJ.classList.add("valid");
    } else {
      specialCharPJ.classList.remove("valid");
    }

    // minLength validation check
    if (length.test(data)) {
      minLengthPJ.classList.add("valid");
    } else {
      minLengthPJ.classList.remove("valid");
    }

    if(length.test(data) && special.test(data) && number.test(data) && upper.test(data) && lower.test(data)){

      document.querySelector(".verificacaoSenhaPJ").style.display = 'block';
      document.getElementById("senhaPJVerificacao").style.display = 'block';
    }
  }

  function inputDigits(component){
    var $inp = $('#'+component+' .digit');

    $inp.on({
      input: function(ev) {
        if(this.value) {
          $inp.eq($inp.index(this) + 1).focus();
        }
      },
      keydown: function(ev) {
        var i = $inp.index(this);
        if(ev.which===8 && !this.value && i) {
          $inp.eq(i - 1).focus();
        }
      }
    });
  }

  function requestFormularioStep1() {
    toggleLoading('#account-pf-step1', true);
    $("#cpf").removeClass("has-error");
    $("#cpf-erro").html("");
    $("#celular").removeClass("has-error");
    $("#celular-erro").html("");
    $("#email").removeClass("has-error");
    $("#email-erro").html("");
    $("#senhaPF").removeClass("has-error");
    $("#senha-erro").html("");

    var formData = new FormData();

    if ($("#senhaPF").val() === $("#senhaPFVerificacao").val()) {
      formData.append('senha', $("#senhaPF").val());
      sessionStorage.setItem("senha", $("#senhaPF").val());

    } else {
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: 'As senhas não batem, digite novamente!',
        showConfirmButton: false,
        timer: 1500
      })
      $('.enviar').html('Cadastrar')

      toggleLoading('#account-pf-step1', false);
      return false
    }

    formData.append('cpf', $("#cpf").val());
    formData.append('celular', $("#telefone").val());
    formData.append('email', $("#email").val());

    sessionStorage.setItem("cpf", $("#cpf").val());
    sessionStorage.setItem("celular", $("#telefone").val());
    sessionStorage.setItem("email", $("#email").val());
    document.getElementById("smsTela").innerHTML = $("#telefone").val()
    document.getElementById("emailTela").innerHTML = $("#email").val()

    $.ajax({
      url: dominio_api + "pfmodulo1.php",
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      async: true,
      statusCode: {
        200: function (result) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: result.resposta.message,
            showConfirmButton: false,
            timer: 1500
          })
          $('.enviar').html('Cadastrar')

          $('section#account-pf-step2').toggleClass('opened');
          toggleLoading('#account-pf-step1', false);
        },
        422: function (result) {
          $('.enviar').html('Cadastrar')

          $.each(result.responseJSON.errors, function (key, value) {
            $("#" + key).addClass("has-error");
            $("#" + key + "-erro").html(value);
          });
          toggleLoading('#account-pf-step1', false);
        },
        403: function (result) {
          alert("Ocorreu um erro, por favor entre em contato com a central!")
          toggleLoading('#account-pf-step1', false);
        },
        500: function (result) {
          alert("Ocorreu um erro, por favor entre em contato com a central!")
          toggleLoading('#account-pf-step1', false);
        }
      }
    });
  }

  function requestResendCode() {
    toggleLoading('#account-pf-step2', true);
    toggleLoading('#account-pf-step3', true);
    var formData = new FormData();
    var cpf = sessionStorage.getItem("cpf");
    var celular = sessionStorage.getItem("celular");
    var email = sessionStorage.getItem("email");
    var senha = sessionStorage.getItem("senha");

    formData.append('cpf', cpf);
    formData.append('celular', celular);
    formData.append('email', email);
    formData.append('senha', senha);

    $.ajax({
      url: dominio_api + "pfmodulo1.php",
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      async: true,
      statusCode: {
        200: function (result) {
          // alert(result.resposta.message)
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'O código foi enviado com sucesso!',
            showConfirmButton: false,
            timer: 1500
          })
          jQuery('.enviar').html('Cadastrar')
          toggleLoading('#account-pf-step2', false);
          toggleLoading('#account-pf-step3', false);
        },
        422: function (result) {
          jQuery('.enviar').html('Cadastrar')
          Swal.fire({
            position: 'top-end',
            icon: 'wwarning',
            title: 'Algo deu errado, tente novamente!',
            showConfirmButton: false,
            timer: 1500
          })
          $.each(result.responseJSON.errors, function (key, value) {
            $("#" + key).addClass("has-error");
            $("#" + key + "-erro").html(value);
          });
          toggleLoading('#account-pf-step2', false);
          toggleLoading('#account-pf-step3', false);
        },
        403: function (result) {
          alert("Ocorreu um erro, por favor entre em contato com a central!")
          toggleLoading('#account-pf-step2', false);
          toggleLoading('#account-pf-step3', false);
        },
        500: function (result) {
          alert("Ocorreu um erro, por favor entre em contato com a central!")
          toggleLoading('#account-pf-step2', false);
          toggleLoading('#account-pf-step3', false);
        }
      }
    });


  }

  function requestResendCodePj() {
    toggleLoading('#account-pj-step2', true);
    toggleLoading('#account-pj-step3', true);
    var formData = new FormData();
    var cpf = sessionStorage.getItem("cpf_responsavel");
    var cnpj = sessionStorage.getItem("cnpj");
    var celular = sessionStorage.getItem("celular");
    var email = sessionStorage.getItem("email");
    var senha = sessionStorage.getItem("senha");
    formData.append('cnpj', cnpj);
    formData.append('cpf_responsavel', cpf);
    formData.append('celular', celular);
    formData.append('email', email);
    formData.append('senha', senha);

    $.ajax({
      url: dominio_api + "pjmodulo1.php",
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      async: true,
      statusCode: {
        200: function (result) {
          // alert(result.resposta.message)
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'O código foi enviado com sucesso!',
            showConfirmButton: false,
            timer: 1500
          })
          jQuery('.enviar').html('Cadastrar')
          toggleLoading('#account-pj-step2', false);
          toggleLoading('#account-pj-step3', false);
        },
        422: function (result) {
          jQuery('.enviar').html('Cadastrar')
          Swal.fire({
            position: 'top-end',
            icon: 'wwarning',
            title: 'Algo deu errado, tente novamente!',
            showConfirmButton: false,
            timer: 1500
          })
          $.each(result.responseJSON.errors, function (key, value) {
            $("#" + key).addClass("has-error");
            $("#" + key + "-erro").html(value);
          });
          toggleLoading('#account-pj-step2', false);
          toggleLoading('#account-pj-step3', false);
        },
        403: function (result) {
          alert("Ocorreu um erro, por favor entre em contato com a central!")
          toggleLoading('#account-pj-step2', false);
          toggleLoading('#account-pj-step3', false);
        },
        500: function (result) {
          alert("Ocorreu um erro, por favor entre em contato com a central!")
          toggleLoading('#account-pj-step2', false);
          toggleLoading('#account-pj-step3', false);
        }
      }
    });


  }

  function requestFormularioValidacao() {
    toggleLoading('#account-pf-step3', true);
    jQuery("#codigo").removeClass("has-error");
    jQuery("#codigo-erro").html("");

    var formData = new FormData();
    var num1 = $("#mail1").val();
    var num2 = $("#mail2").val();
    var num3 = $("#mail3").val();
    var num4 = $("#mail4").val();
    var num5 = $("#mail5").val();
    var num6 = $("#mail6").val();
    var codigoMail = num1 + num2 + num3 + num4 + num5 + num6;
    var cpf = sessionStorage.getItem("cpf");
    var celular = sessionStorage.getItem("celular");
    var email = sessionStorage.getItem("email");
    var senha = sessionStorage.getItem("senha");
    var smsCode = sessionStorage.getItem("codigoSms");

    if(smsCode == ''){
      smsCode = "000000"
    }

    if(codigoMail == ''){
      codigoMail = "000000"
    }

    formData.append('codigo_enviado_email', codigoMail);
    formData.append('codigo_enviado_sms', smsCode);
    formData.append('cpf', cpf);
    formData.append('celular', celular);
    formData.append('email', email);
    formData.append('senha', senha);

    $.ajax({
      url: dominio_api + "pfmodulo1.php",
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      async: true,
      statusCode: {
        200: function (result) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: result.resposta.message,
            showConfirmButton: false,
            timer: 1500
          })
          $('section#account-pf-step4').toggleClass('opened');
          jQuery('.enviar').html('Cadastrar')
          toggleLoading('#account-pf-step3', false);
        },
        422: function (result) {
          jQuery('.enviar').html('Cadastrar')
          if(result.responseJSON.errors.codigo_enviado_email != undefined){
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: result.responseJSON.errors.codigo_enviado_email,
              showConfirmButton: false,
              timer: 3500
            })
          }else{
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: result.responseJSON.errors.cpf,
              showConfirmButton: false,
              timer: 3500
            })
            $('section#create-account').toggleClass('opened');
          }

          $.each(result.responseJSON.errors, function (key, value) {
            $("#" + key).addClass("has-error");
            $("#" + key + "-erro").html(value);
          });
          toggleLoading('#account-pf-step3', false);
        },
        403: function (result) {
          alert("Ocorreu um erro, por favor entre em contato com a central!")
          toggleLoading('#account-pf-step3', false);
        },
        500: function (result) {
          alert("Ocorreu um erro, por favor entre em contato com a central!")
          toggleLoading('#account-pf-step3', false);
        }
      }
    });
  }

  function requestFormularioValidacaoPj() {
    toggleLoading('#account-pj-step3', true);
    var formData = new FormData();
    var num1 = $("#mailPj1").val();
    var num2 = $("#mailPj2").val();
    var num3 = $("#mailPj3").val();
    var num4 = $("#mailPj4").val();
    var num5 = $("#mailPj5").val();
    var num6 = $("#mailPj6").val();
    var codigoMailPj = num1 + num2 + num3 + num4 + num5 + num6;

    var cnpj = sessionStorage.getItem("cnpj");
    var cpf = sessionStorage.getItem("cpf_responsavel");
    var celular = sessionStorage.getItem("celular");
    var email = sessionStorage.getItem("email");
    var senha = sessionStorage.getItem("senha");
    var codigoSmsPj = sessionStorage.getItem("codigoSmsPj");

    if (codigoSmsPj == ''){
      codigoSmsPj = "000000"
    }

    if (codigoMailPj == ''){
      codigoMailPj = "000000"
    }

    formData.append('cnpj', cnpj);
    formData.append('codigo_enviado_email', codigoMailPj);
    formData.append('codigo_enviado_sms', codigoSmsPj);
    formData.append('cpf_responsavel', cpf);
    formData.append('celular', celular);
    formData.append('email', email);
    formData.append('senha', senha);



    $.ajax({
      url: dominio_api + "pjmodulo1.php",
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      async: true,
      statusCode: {
        200: function (result) {
          // alert(result.resposta.message)
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: result.resposta.message,
            showConfirmButton: false,
            timer: 1500
          })
          sessionStorage.setItem("atividade_principal", result.resposta.dados.empresa.atividade_principal);
          sessionStorage.setItem("data_da_situacao", result.resposta.dados.empresa.data_da_situacao);
          sessionStorage.setItem("data_da_situacao_especial", result.resposta.dados.empresa.data_da_situacao_especial);
          sessionStorage.setItem("data_de_abertura", result.resposta.dados.empresa.data_de_abertura);
          sessionStorage.setItem("data_e_hora_da_consulta", result.resposta.dados.empresa.data_e_hora_da_consulta);
          sessionStorage.setItem("motivo_da_situacao", result.resposta.dados.empresa.motivo_da_situacao);
          sessionStorage.setItem("natureza_juridica", result.resposta.dados.empresa.natureza_juridica);
          sessionStorage.setItem("nome_da_empresa", result.resposta.dados.empresa.nome_da_empresa);
          sessionStorage.setItem("nome_fantasia", result.resposta.dados.empresa.nome_fantasia);
          sessionStorage.setItem("numero_plugin", result.resposta.dados.empresa.numero_plugin);
          sessionStorage.setItem("situacao_cadastral", result.resposta.dados.empresa.situacao_cadastral);
          sessionStorage.setItem("situacao_especial", result.resposta.dados.empresa.situacao_especial);

          sessionStorage.setItem("bairro", result.resposta.dados.endereco.bairro);
          sessionStorage.setItem("cep", result.resposta.dados.endereco.cep);
          sessionStorage.setItem("cidade", result.resposta.dados.endereco.cidade);
          sessionStorage.setItem("complemento", result.resposta.dados.endereco.complemento);
          sessionStorage.setItem("data_e_hora_da_consulta", result.resposta.dados.endereco.data_e_hora_da_consulta);
          sessionStorage.setItem("estado", result.resposta.dados.endereco.estado);
          sessionStorage.setItem("numero", result.resposta.dados.endereco.numero);
          sessionStorage.setItem("numero_plugin", result.resposta.dados.endereco.numero_plugin);
          sessionStorage.setItem("rua", result.resposta.dados.endereco.rua);

          document.getElementById("accountPJ_CompanyName").value = result.resposta.dados.empresa.nome_da_empresa;
          document.getElementById("accountPJ_Birthday").value = result.resposta.dados.empresa.data_de_abertura;
          document.getElementById("accountPJ_FantasyName").value = result.resposta.dados.empresa.nome_fantasia;
          document.getElementById("accountPJ_Activity").value = result.resposta.dados.empresa.atividade_principal;
          document.getElementById("accountPJ_Nature").value = result.resposta.dados.empresa.natureza_juridica;

          document.getElementById("accountPJ_Address").value = result.resposta.dados.endereco.rua;
          document.getElementById("accountPJ_Number").value = result.resposta.dados.endereco.numero;
          document.getElementById("accountPJ_Complement").value = result.resposta.dados.endereco.complemento;
          document.getElementById("accountPJ_Neighborhood").value = result.resposta.dados.endereco.bairro;
          document.getElementById("accountPJ_City").value = result.resposta.dados.endereco.cidade;
          document.getElementById("accountPJ_State").value = result.resposta.dados.endereco.estado;
          document.getElementById("accountPJ_PostalCode").value = result.resposta.dados.endereco.cep;
          $('section#account-pj-step4').toggleClass('opened');
          jQuery('.enviar').html('Cadastrar')
          toggleLoading('#account-pj-step3', false);
        },
        422: function (result) {
          jQuery('.enviar').html('Cadastrar')
          if(result.responseJSON.errors.codigo_enviado_email != undefined){
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: result.responseJSON.errors.codigo_enviado_email,
              showConfirmButton: false,
              timer: 3500
            })
          }else if(result.responseJSON.errors.cnpj != undefined){
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: result.responseJSON.errors.cnpj,
              showConfirmButton: false,
              timer: 3500
            })
            $('section#create-account').toggleClass('opened');

          }else if(result.responseJSON.errors.cpf_responsavel != undefined){
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: result.responseJSON.errors.cpf_responsavel,
              showConfirmButton: false,
              timer: 3500
            })
            $('section#create-account').toggleClass('opened');

          }
          $.each(result.responseJSON.errors, function (key, value) {
            $("#" + key).addClass("has-error");
            $("#" + key + "-error").html(value);
          });
          toggleLoading('#account-pj-step3', false);
        },
        403: function (result) {
          alert("Ocorreu um erro, por favor entre em contato com a central!")
          toggleLoading('#account-pj-step3', false);
        },
        500: function (result) {
          alert("Ocorreu um erro, por favor entre em contato com a central!")
          toggleLoading('#account-pj-step3', false);
        }
      }
    });
  }

  function  DadosPfEtapa4() {
    sessionStorage.setItem("nome_do_cliente", $("#accountPF_Name").val());
    sessionStorage.setItem("data_nascimento", $("#accountPF_Birthday").val());
    sessionStorage.setItem("rg", $("#accountPF_Identity").val());
    sessionStorage.setItem("rg_data_emissao", $("#accountPF_IdentityDate").val());
    sessionStorage.setItem("rg_uf_emissor", $("#accountPF_IdentityState").val());
    sessionStorage.setItem("renda", $("#renda").val());

    if($("#accountPF_Name").val() != '' && $("#accountPF_Birthday").val() != '' && $("#accountPF_Identity").val() != '' && $("#accountPF_IdentityDate").val() != '' && $("#accountPF_IdentityState").val() != '' && $("#renda").val() != '' ){
      jQuery('.enviar').html('Cadastrar')

      $('section#account-pf-step5').toggleClass('opened');
    }else{
      jQuery('.enviar').html('Cadastrar')

      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: 'Por favor complete todos os campos abaixo!',
        showConfirmButton: false,
        timer: 2000
      })
    }
  }

  function codigoSMS() {
    var num1 = $("#sms1").val();
    var num2 = $("#sms2").val();
    var num3 = $("#sms3").val();
    var num4 = $("#sms4").val();
    var num5 = $("#sms5").val();
    var num6 = $("#sms6").val();
    var codigoSms = num1 + num2 + num3 + num4 + num5 + num6;

    if (codigoSms.length !== 6) {
      Swal.fire({
        position: 'top-end',
        icon: 'wwarning',
        title: 'Preencha o codigo SMS enviado!',
        showConfirmButton: false,
        timer: 1500
      });
      return;
    }

    sessionStorage.setItem("codigoSms", codigoSms);
    $('section#account-pf-step3').toggleClass('opened');
  }

  function codigoSMSPj() {
    var num1 = $("#smsPj1").val();
    var num2 = $("#smsPj2").val();
    var num3 = $("#smsPj3").val();
    var num4 = $("#smsPj4").val();
    var num5 = $("#smsPj5").val();
    var num6 = $("#smsPj6").val();
    var codigoSmsPj = num1 + num2 + num3 + num4 + num5 + num6;

    if (codigoSmsPj.length !== 6) {
      Swal.fire({
        position: 'top-end',
        icon: 'wwarning',
        title: 'Preencha o codigo SMS enviado!',
        showConfirmButton: false,
        timer: 1500
      });
      return;
    }

    sessionStorage.setItem("codigoSmsPj", codigoSmsPj);
    $('section#account-pj-step3').toggleClass('opened');

  }

  function inputFilePf(){
    document.getElementById("abrirCameraPfParteUm").style.display = "block";
  }

  function inputFilePf2(){
    document.getElementById("abrirCameraPfSelfie").style.display = "block";
  }

  function loadCamera(){

    var imageFile = $('#imageFile')[0].files[0]
    if(imageFile != undefined){
      document.querySelector(".msgUser1").style.display = "block"
      $('section#account-pf-step6').toggleClass('opened');
    } else {
      document.querySelector(".msgUser1").style.display = "none"
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: 'Por favor tire a selfie para prosseguir!',
        showConfirmButton: false,
        timer: 1500
      })
    }
  }

  function inputFilePj(){
    document.getElementById("abrirCameraPjParteUm").style.display = "block";
  }

  function inputFilePj2(){
    document.getElementById("abrirCameraPjSelfie").style.display = "block";
  }

  function loadCameraPj(){

    var imageFile = $('#imageFilePj')[0].files[0]
    if(imageFile != undefined){
      document.querySelector(".msgUser1Pj").style.display = "block"
      $('section#account-pj-step6').toggleClass('opened');
    } else {
      document.querySelector(".msgUser1Pj").style.display = "none"
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: 'Por favor tire a selfie para prosseguir!',
        showConfirmButton: false,
        timer: 1500
      })
    }
  }

  function requestFormularioEndereco() {
    toggleLoading('#account-pf-step7', true);
    jQuery("#cep-erro").html("");
    jQuery("#logradouro-erro").html("");
    jQuery("#bairro-erro").html("");
    jQuery("#uf-erro").html("");
    jQuery("#cidade-erro").html("");

    var formData = new FormData();
    var cpf = sessionStorage.getItem("cpf");
    var cep = $("#accountPF_PostalCode").val().replace(/\D/g, '');

    formData.append('cpf', cpf);
    formData.append('logradouro', $("#accountPF_Address").val());
    formData.append('numero', $("#accountPF_Number").val());
    formData.append('complemento', $("#accountPF_Complement").val());
    formData.append('bairro', $("#accountPF_Neighborhood").val());
    formData.append('cidade', $("#accountPF_City").val());
    formData.append('uf', $("#accountPF_State").val());
    formData.append('cep', cep);
    formData.append('comprovante_endereco', $('#comprovanteEndereco')[0].files[0]);

    $.ajax({
      url: dominio_api + "modulo3.php",
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      async: true,
      statusCode: {
        200: function (result) {
          // alert(result.resposta.message)
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: result.resposta.message,
            showConfirmButton: false,
            timer: 1500
          })
          $('section#account-pf-feedback').toggleClass('opened');
          jQuery('.enviar').html('Cadastrar')
          toggleLoading('#account-pf-step7', false);
        },
        422: function (result) {
          jQuery('.enviar').html('Cadastrar')
          $.each(result.responseJSON.errors, function (key, value) {
            $("#" + key).addClass("has-error");
            $("#" + key + "-erro").html(value);
          });
          toggleLoading('#account-pf-step7', false);
        },
        403: function (result) {
          alert("Ocorreu um erro, por favor entre em contato com a central!")
          toggleLoading('#account-pf-step7', false);
        },
        500: function (result) {
          alert("Ocorreu um erro, por favor entre em contato com a central!")
          toggleLoading('#account-pf-step7', false);
        }
      }
    });
  }

  function requestFormularioEnderecoPJ() {
    toggleLoading('#account-pj-step7', true);
    toggleLoading('#account-pj-step8', true);
    var cnpj = sessionStorage.getItem("cnpj");
    var formData = new FormData();
    // var cep = $("#cep").val().replace(/\D/g, '');

    formData.append('logradouro', $("#accountPJ_Address").val());
    formData.append('numero', $("#accountPJ_Number").val());
    formData.append('complemento', $("#accountPJ_Complement").val());
    formData.append('bairro', $("#accountPJ_Neighborhood").val());
    formData.append('cidade', $("#accountPJ_City").val());
    formData.append('uf', $("#accountPJ_State").val());
    formData.append('cep', $("#accountPJ_PostalCode").val());
    formData.append('cnpj', cnpj);


    $.ajax({
      url: dominio_api + "modulo3.php",
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      async: true,
      statusCode: {
        200: function (result) {
          // alert(result.resposta.message)
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: result.resposta.message,
            showConfirmButton: false,
            timer: 1500
          })
          $('section#account-pj-feedback').toggleClass('opened');
          jQuery('.enviar').html('Cadastrar')
          toggleLoading('#account-pj-step7', false);
          toggleLoading('#account-pj-step8', false);
        },
        422: function (result) {
          jQuery('.enviar').html('Cadastrar')

          $.each(result.responseJSON.errors, function (key, value) {
            $("#" + key).addClass("has-error");
            $("#" + key + "-erro").html(value);
          });
          toggleLoading('#account-pj-step7', false);
          toggleLoading('#account-pj-step8', false);
        },
        403: function (result) {
          alert("Ocorreu um erro, por favor entre em contato com a central!")
          toggleLoading('#account-pj-step7', false);
          toggleLoading('#account-pj-step8', false);
        },
        500: function (result) {
          alert("Ocorreu um erro, por favor entre em contato com a central!")
          toggleLoading('#account-pj-step7', false);
          toggleLoading('#account-pj-step8', false);
        }
      }
    });


  }

  function requestFormularioSelfie() {
    toggleLoading('#account-pf-step6', true);
    jQuery("#nome_do_cliente-erro").html("");
    jQuery("#data_nascimento-erro").html("");
    jQuery("#rg_data_emissao-erro").html("");
    jQuery("#rg_uf_emissor-erro").html("");
    jQuery("#renda-erro").html("");

    var formData = new FormData();
    var cpf = sessionStorage.getItem("cpf");
    var nome_do_cliente = sessionStorage.getItem("nome_do_cliente");
    var data_nascimento = sessionStorage.getItem("data_nascimento");
    var rg = sessionStorage.getItem("rg");
    var rg_data_emissao = sessionStorage.getItem("rg_data_emissao");
    var rg_uf_emissor = sessionStorage.getItem("rg_uf_emissor");
    var renda = sessionStorage.getItem("renda");

    var imageFile = $('#imageFile2')[0].files[0]
    if(imageFile != undefined){
      document.querySelector(".msgUser2").style.display = "block"
      formData.append('documento', $('#imageFile2')[0].files[0]);
    }
    formData.append('cpf', cpf);
    formData.append('nome_do_cliente', nome_do_cliente);
    formData.append('data_nascimento', data_nascimento);
    formData.append('rg', rg);
    formData.append('rg_data_emissao', rg_data_emissao);
    formData.append('rg_uf_emissor', rg_uf_emissor);
    formData.append('renda', renda);
    formData.append('selfie', $('#imageFile')[0].files[0]);
    $.ajax({
      url: dominio_api + "pfmodulo2.php",
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      async: true,
      statusCode: {
        200: function (result) {
          // alert(result.resposta.message)
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: result.resposta.message,
            showConfirmButton: false,
            timer: 2500
          })
          $('section#account-pf-step7').toggleClass('opened');
          jQuery('.enviar').html('Cadastrar')
          toggleLoading('#account-pf-step6', false);
        },
        422: function (result) {
          // console.log(result.responseJSON.errors.selfie)
          jQuery('.enviar').html('Cadastrar')
          if(result.responseJSON.errors.selfie != undefined){
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: result.responseJSON.errors.selfie,
              showConfirmButton: false,
              timer: 3500
            })
          }else if(result.responseJSON.errors.documento != undefined){
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: result.responseJSON.errors.documento,
              showConfirmButton: false,
              timer: 3500
            })
          }else{
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: "Alguns dados se encontram inconsistentes, por favor volte e verifique os dados",
              showConfirmButton: false,
              timer: 3500
            })
          }

          $.each(result.responseJSON.errors, function (key, value) {
            $("#" + key).addClass("has-error");
            $("#" + key + "-erro").html(value);
          });
          toggleLoading('#account-pf-step6', false);
          // $('section#account-pf-step4').toggleClass('opened');
        },
        403: function (result) {
          alert("Ocorreu um erro, por favor entre em contato com a central!")
          toggleLoading('#account-pf-step6', false);
        },
        500: function (result) {
          alert("Ocorreu um erro, por favor entre em contato com a central!")
          toggleLoading('#account-pf-step6', false);
        }
      }
    });
  }

  function requestFormularioSelfiePj() {
    toggleLoading('#account-pj-step6', true);
    jQuery("#selfie-erro").html("");


    var formData = new FormData();
    var cnpj = sessionStorage.getItem("cnpj");

    var imageFile = $('#imageFilePj2')[0].files[0]
    if(imageFile != undefined){
      document.querySelector(".msgUser2Pj").style.display = "block"
      formData.append('documento', $('#imageFilePj2')[0].files[0]);
    }
    formData.append('cnpj', cnpj);
    formData.append('selfie', $('#imageFilePj')[0].files[0]);

    $.ajax({
      url: dominio_api + "pjmodulo2.php",
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      async: true,
      statusCode: {
        200: function (result) {
          // alert(result.resposta.message)
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: result.resposta.message,
            showConfirmButton: false,
            timer: 1500
          })
          $('section#account-pj-step7').toggleClass('opened');
          jQuery('.enviar').html('Cadastrar')
          toggleLoading('#account-pj-step6', false);
        },
        422: function (result) {
          jQuery('.enviar').html('Cadastrar')
          if(result.responseJSON.errors.selfie != undefined){
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: result.responseJSON.errors.selfie,
              showConfirmButton: false,
              timer: 3500
            })
          }else if(result.responseJSON.errors.documento != undefined){
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: result.responseJSON.errors.documento,
              showConfirmButton: false,
              timer: 3500
            })
          }else{
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: 'Alguns dados se encontram inconsistentes, por favor volte e verifique os dados!',
              showConfirmButton: false,
              timer: 3500
            })
          }
          $.each(result.responseJSON.errors, function (key, value) {
            $("#" + key).addClass("has-error");
            $("#" + key + "-erro").html(value);
          });
          toggleLoading('#account-pj-step6', false);
        },
        403: function (result) {
          alert("Ocorreu um erro, por favor entre em contato com a central!")
          toggleLoading('#account-pj-step6', false);
        },
        500: function (result) {
          alert("Ocorreu um erro, por favor entre em contato com a central!")
          toggleLoading('#account-pj-step6', false);
        }
      }
    });
  }

  function requestFormularioEtapa1PJ() {
    toggleLoading('#account-pj-step1', true);
    jQuery("#cnpj-error").html("");
    jQuery("#celular-error").html("");
    jQuery("#email-error").html("");
    jQuery("#cpf_responsavel-error").html("");

    jQuery("#senha-error").html("");

    var formData = new FormData();

    if (jQuery("#senhaPJ").val() === jQuery("#senhaPJVerificacao").val()) {
      formData.append('senha', jQuery("#senhaPJ").val());
      sessionStorage.setItem("senha", jQuery("#senhaPJ").val());
    } else {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'As senhas não batem, digite novamente!',
        showConfirmButton: false,
        timer: 1500
      })
      toggleLoading('#account-pj-step1', false);
      return false
    }
    formData.append('cnpj', $("#cnpj").val());
    formData.append('celular', $("#celularPJ").val());
    formData.append('email', $("#emailPJ").val());
    // formData.append('nome_responsavel', $("#nomeResponsavel").val());
    formData.append('cpf_responsavel', $("#cpf_responsavel").val());

    sessionStorage.setItem("cnpj", $("#cnpj").val());
    sessionStorage.setItem("cpf_responsavel", $("#cpf_responsavel").val());
    sessionStorage.setItem("celular", $("#celularPJ").val());
    sessionStorage.setItem("email", $("#emailPJ").val());
    document.getElementById("smsTelaPj").innerHTML = $("#celularPJ").val()
    document.getElementById("emailTelaPj").innerHTML = $("#emailPJ").val()
    $.ajax({
      url: dominio_api + "pjmodulo1.php",
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      async: true,
      statusCode: {
        200: function (result) {
          // alert(result.resposta.message)
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: result.resposta.message,
            showConfirmButton: false,
            timer: 1500
          })
          $('section#account-pj-step2').toggleClass('opened');

          jQuery('.enviar').html('Cadastrar')
          toggleLoading('#account-pj-step1', false);
        },
        422: function (result) {
          jQuery('.enviar').html('Cadastrar')


          $.each(result.responseJSON.errors, function (key, value) {
            $("#" + key).addClass("has-error");
            $("#" + key + "-error").html(value);
          });
          toggleLoading('#account-pj-step1', false);
        },
        403: function (result) {
          alert("Ocorreu um erro, por favor entre em contato com a central!")
          toggleLoading('#account-pj-step1', false);
        },
        500: function (result) {
          alert("Ocorreu um erro, por favor entre em contato com a central!")
          toggleLoading('#account-pj-step1', false);
        }
      }
    });
  }

  // Orgão emissor
  const issuingEntities = {
    'AC': 'AC',
    'AL': 'AL',
    'AP': 'AP',
    'AM': 'AM',
    'BA': 'BA',
    'CE': 'CE',
    'DF': 'DF',
    'ES': 'ES',
    'GO': 'GO',
    'MA': 'MA',
    'MT': 'MT',
    'MS': 'MS',
    'MG': 'MG',
    'PA': 'PA',
    'PB': 'PB',
    'PR': 'PR',
    'PE': 'PE',
    'PI': 'PI',
    'RJ': 'RJ',
    'RN': 'RN',
    'RS': 'RS',
    'RO': 'RO',
    'RR': 'RR',
    'SC': 'SC',
    'SP': 'SP',
    'SE': 'SE',
    'TO': 'TO'
  };

  function inverse(obj){
    var retobj = {};
    for(var key in obj){
      retobj[obj[key]] = key;
    }
    return retobj;
  }
  student = inverse(issuingEntities);
  const languagesSelect = document.getElementById("accountPF_IdentityState");

  for(language in issuingEntities) {
    option = new Option(issuingEntities[language], language);
    languagesSelect.options[languagesSelect.options.length] = option;
  }
});


